<script>
import { debounce } from 'lodash-es'
import ChooseEtablissement from '@/components/compte/chooseEtablissement'
export default {
  name: 'profilEdition',
  components: {
    ChooseEtablissement
  },
  props: {
    user: { required: false, type: Object, default: () => {} },
    show: { required: true, type: Boolean }
  },
  data () {
    return {
      showSuccessCreation: false,
      phoneInput: null,
      showProfilEdition: false,
      generateTag: null,
      generateCode: null,
      showError: false,
      errorDetail: null,
      menu: false,
      courrielRules: [
        v => !!v || this.$i18n.t('rules.courriel-obligatoire'),
        v => /.+@.+/.test(v) || this.$i18n.t('rules.courriel-invalide'),
        () => !this.courrielExist || this.$i18n.t('rules.courriel-existant')
      ],
      date: this.user && this.user.info.dateNaissance ? this.user.info.dateNaissance : null,
      prenom: this.user?.info?.prenom ?? (this.user?.extra?.prenom ?? null),
      nom: this.user?.info?.nom ?? (this.user?.extra?.nom ?? null),
      telephone: null,
      telephoneExist: false,
      telephoneRules: [
        v => !!v || this.$i18n.t('rules.telephone-obligatoire'),
        // eslint-disable-next-line no-useless-escape
        v => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || this.$i18n.t('rules.telephone-invalide'),
        () => !this.telephonexist || this.$i18n.t('rules.telephone-existant')
      ],
      courriel: null,
      courrielExist: false,
      etablissement: null,
      loading: false
    }
  },
  computed: {
    messageError () {
      let temp = null
      if (this.errorDetail) {
        if (this.errorDetail.includes('Info telephone')) {
          temp = this.$t('alerte.explication-erreur-telephone-existant')
        } else if (this.errorDetail.includes('Info courriel')) {
          temp = this.$t('alerte.explication-erreur-courriel-existant')
        } else {
          temp = this.$route.query.create ? this.$t('alerte.explication-erreur-creation-profil') : this.$t('alerte.explication-erreur-maj-profil')
        }
      }
      return temp
    },
    alreadyGotMail () {
      let temp = false
      const user = this.$cookies.get('EcoleDokomaUser').user
      if (user && user.info && user.info.email) {
        temp = true
      }
      return temp
    },
    alreadyGotPhone () {
      let temp = false
      const user = this.$cookies.get('EcoleDokomaUser').user
      if (user && user.extra && user.extra.telephone) {
        temp = true
      }
      return temp
    },
    validatedForm () {
      if (this.prenom && this.nom && this.courriel && this.telephone && this.etablissement) {
        return true
      } else {
        return false
      }
    }
  },
  async created () {
    if (this.$route.query.create) {
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/tagsSalleClasse.gql'),
        variables: {
          total: 1
        }
      }).then(({ data }) => {
        this.generateTag = data.tagsSalleClasse[0]
      })
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/accessCodeSalleClasse.gql'),
        variables: {
          total: 1
        }
      }).then(({ data }) => {
        this.generateCode = data.accessCodeSalleClasse[0]
      })
    }
  },
  watch: {
    user: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          console.log('mon user', newVal)
          if (newVal.info && newVal.info.email) {
            this.courriel = newVal.info.email
          }
          if (newVal.extra && newVal.extra.telephone) {
            this.telephone = newVal.extra.telephone
            this.phoneInput = newVal.extra.telephone
          }
        }
      }
    },
    show: {
      immediate: true,
      handler (newVal) {
        this.showProfilEdition = newVal
      }
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    clearAndGoToHome () {
      this.clearDialog()
      this.$router.push({ name: 'home___' + this.$i18n.locale, params: { lang: this.$i18n.locale } })
    },
    clearDialog (errorOnly) {
      this.showError = false
      this.loading = false
      if (!errorOnly) {
        this.$emit('closeDialog', false)
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    chooseEtablissement (etablissement) {
      this.etablissement = etablissement
    },
    setPhoneNumber (formattedNumber, { number, valid, country }) {
      this.phoneValid = valid
      this.telephone = formattedNumber.significant ?? formattedNumber
    },
    checkExistInfoUsager: debounce(async function (value) {
      this.courrielExist = false
      this.telephonexist = false
      if ((!this.alreadyGotMail && this.courrielRules[1](this.courriel) === true) || (!this.alreadyGotPhone && this.telephoneRules[1](this.telephone) === true)) {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/existSalleClasseUsager.gql'),
          variables: {
            identifiant: this.alreadyGotMail ? this.telephone : this.courriel
          }
        }).then(({ data }) => {
          if (!data.existSalleClasseUsager) {
            if (this.alreadyGotMail) {
              this.courrielExist = true
            } else {
              this.telephonexist = true
            }
          }
        })
      }
    }, 500, { trailing: true }),
    checkBeforeCreate () {
      console.log('valid ?', this.validatedForm)
      if (this.validatedForm) {
        this.showSuccessCreation = false
        this.loading = true
        this.showError = false
        if (this.$route.query.create) {
          this.createAccount()
        } else {
          this.updateAccount()
        }
      }
    },
    async createAccount () {
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/createSalleClasseUsager.gql'),
          variables: {
            username: this.generateTag,
            accessCode: this.generateCode,
            email: this.courriel,
            attrs: {
              role: 'SALLE_CLASSE_ADMINISTRATION',
              compagnies: [this.etablissement]
            },
            info: {
              locale: this.$i18n.locale,
              nom: this.nom,
              prenom: this.prenom,
              telephone: this.telephone
            }
          }
        }).then(async ({ data }) => {
          if (data.createSalleClasseUsager && !data.createSalleClasseUsager.errors) {
            this.$store.commit('User/setCheckAccount', false)
            await this.$store.dispatch('User/loginUser', { user: this.$cookies.get('EcoleDokomaUser').user, router: this.$router, idConnexion: this.$cookies.get('EcoleDokomaUser').oauth_token, i18n: this.$i18n })
            await this.$store.dispatch('User/fetchMinProfile')
            if (this.$route.query.create) {
              this.updateAccount(true)
            } else {
              this.$store.dispatch('App/setSnackBarNotification', {
                type: 'success',
                description: this.$t('alerte.creation-profil-succes')
              })
              this.loading = false
            }
          } else {
            if (data.createSalleClasseUsager.errors) {
              this.errorDetail = data.createSalleClasseUsager.errors
            }
            throw Error(data.createSalleClasseUsager.errors || 'erreur creation')
          }
        })
      } catch (e) {
        this.loading = false
        this.showError = true
        /* eslint-disable-next-line */
        console.error(e)
      }
    },
    async updateAccount (auth) {
      const variables = {
        attrs: {
          role: this.role,
          compagnies: [this.etablissement]
        },
        info: {
          locale: this.$i18n.locale,
          nom: this.nom,
          prenom: this.prenom,
          telephone: this.telephone,
          genre: this.genre,
          dateNaissance: this.dateNaissance
        }
      }
      if (auth) {
        variables.authentification = { provider: this.$cookies.get('EcoleDokomaUser').user.provider, uid: this.$cookies.get('EcoleDokomaUser').user.id }
      }
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/updateSalleClasseUsager.gql'),
          variables
        }).then(async ({ data }) => {
          if (data.updateSalleClasseUsager && !data.updateSalleClasseUsager.errors) {
            await this.$store.dispatch('User/fetchMinProfile')
            if (!this.$route.query.create) {
              this.$store.dispatch('App/setSnackBarNotification', {
                type: 'success',
                description: this.$t('alerte.update-profil-succes')
              })
            } else {
              this.showSuccessCreation = true
            }
          } else {
            if (data.createSalleClasseUsager.errors) {
              this.errorDetail = data.createSalleClasseUsager.errors
            }
            throw Error(data.createSalleClasseUsager.errors || 'erreur creation')
          }
        })
      } catch (e) {
        this.loading = false
        this.showError = true
        /* eslint-disable-next-line */
        console.error(e)
      }
    }
  }
}
</script>

<template lang='pug'>
div.profil-edition
  v-dialog(v-if='showProfilEdition', v-model='showProfilEdition', max-width='600', content-class='custom-dialog', persistent, light)
    v-card.light()
      template(v-if='!$route.query.create')
        v-hover(v-slot:default="{ hover }")
          v-btn.dialog-button(@click='clearDialog', depressed, fab, x-large, color='secondary')
            font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title {{ $route.query && $route.query.create ? $t('action.creation-profil') : $t('action.modif-profil') }}
      v-card-text.relative
        v-form(ref='profilUpdate')
          div
            label {{ $t('partage.prenom') }}
            v-text-field(:placeholder="$t('partage.prenom')", solo, flat, outlined, hide-details, v-model='prenom', clearable)
          div.mt2
            label {{ $t('partage.nom') }}
            v-text-field(:placeholder="$t('partage.nom')", solo, flat, outlined, hide-details, v-model='nom', clearable)
          div.mt2
            label {{ $t('partage.etablissement') }}
            choose-etablissement(@select='chooseEtablissement', :datetab='etablissement')
          div.mt2
            label {{ $tc('form.courriel', 2) }}
            v-text-field(v-model='courriel', :placeholder="$tc('form.courriel', 1)", filled, rounded, required, :rules='courrielRules', :disabled='alreadyGotMail', color='secondary', @keydown='checkExistInfoUsager')
          div.mt2
            label {{ $tc('form.telephone', 2) }}
            v-text-field(v-model='telephone', :placeholder='$t("form.telephone-consigne")', :disabled='alreadyGotPhone', color='secondary', filled, rounded)
            //- vue-tel-input-vuetify.custom-shadow(v-model='phoneInput', :onlyCountries='countriesFromZones', :label="$t('form.telephone')", :placeholder='$t("form.telephone-consigne")', filled, rounded, @input='setPhoneNumber', :valid-characters-only='true', disabled=true, color='secondary')
      v-btn(@click='checkBeforeCreate', color='secondary', depressed, block, x-large, :dark='!validatedForm', :disabled='!validatedForm')
        span.f4 {{ $t('action.sauvegarder') }}
  v-dialog(v-model='loading', max-width='500', content-class='custom-dialog', persistent)
    v-card
      v-card-title.dialog-title.white--text {{ $route.query && $route.query.create ? $t('alerte.creation-profil-en-cours') : $t('alerte.mis-a-jour-compte') }}
      v-card-text
        v-progress-circular(indetrminate, color='secondary')
  v-dialog(v-model='showError', max-width='500', content-class='custom-dialog', persistent)
    v-card.error()
      v-card-title.dialog-title.white--text {{ $route.query.create ? $t('alerte.echec-creation') : $t('alerte.echec-modification') }}
      v-card-text.white--text
        div.f5.mb3.tc {{ messageError }}
      v-card-actions.secondary
        v-spacer
        v-btn(@click='clearDialog(true)', outlined, color='light') {{ $t('action.fermer') }}
  v-dialog(v-model='showSuccessCreation', max-width='500', color='primary', content-class='custom-dialog', persistent)
    v-card.success()
      v-card-title.dialog-title.white--text {{ $t('alerte.creation-profil-succes') }}
      v-card-text.white--text
        div.f5.mb3.tc.b {{ $t('compte.instruction-compte-creer') }}
        div.f5.mb3.tc {{ $t('compte.attente-validation') }}
      v-card-actions.secondary
        v-spacer
        v-btn(@click='clearAndGoToHome', outlined, color='light') {{ $t('action.fermer') }}
</template>
<style lang='sass'>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg
  background-color: black !important
</style>

<script>
// import AvatarSelection from '@/components/dialogs/profile/avatarSelection'
import ProfilEdition from '@/components/profile/profilEdition'
// import StatsPreview from '@/components/statistiques/statsPreview.vue'
import PageHeader from '@/components/shared/pageHeader'

export default {
  name: 'profil',
  components: {
    // AvatarSelection,
    ProfilEdition,
    // StatsPreview
    PageHeader
  },
  data () {
    return {
      courriel: null,
      loadElem: false,
      elementsCrees: null,
      elementsMoisCourant: null,
      showProfilEdition: false
    }
  },
  computed: {
    user () {
      if (this.$route.query && this.$route.query.create) {
        return this.$cookies.get('EcoleDokomaUser').user
      } else {
        return this.$store.state.User.profile
      }
    }
    // showFusion: {
    //   set (val) { this.$store.commit('Dialogs/setFusionCompte', val) },
    //   get () { return this.$store.state.Dialogs.showFusionCompte }
    // },
    // showAvatarSelection: {
    //   set (val) { this.$store.commit('Dialogs/setAvatarSelection', val) },
    //   get () { return this.$store.state.Dialogs.showAvatarSelection }
    // },
    // showCodeConfirmation: {
    //   set (val) { this.$store.commit('Dialogs/setCodeConfirmation', val) },
    //   get () { return this.$store.state.Dialogs.showCodeConfirmation }
    // }
  },
  watch: {
    '$route': {
      immediate: true,
      handler (newVal) {
        if (newVal.query && newVal.query.create) {
          this.showProfilEdition = true
        }
      }
    }
  },
  created () {
    this.$store.dispatch('User/fetchMinProfile')
  },
  // apollo: {
  //   elementsCrees: {
  //     query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
  //     update (data) {
  //       return data.calendrierCalendrierElements
  //     },
  //     watchLoading (isLoading) {
  //       this.loadElem = isLoading
  //     }
  //   },
  //   elementsMoisCourant: {
  //     query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
  //     variables () {
  //       const date = new Date()
  //       const dat = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  //       return {
  //         filtre: {
  //           fin: dat
  //         }
  //       }
  //     },
  //     update (data) {
  //       // COMME ON REGROUPE PAR BATCH
  //       // TODO UNE REQUËTE POUR RENVOYER DES ELEMENTS UNIQUES ACTUELLEMENTS ON A DES DOUBLONS
  //       return data.calendrierCalendrierElements
  //     },
  //     watchLoading (isLoading) {
  //       this.loadElem = isLoading
  //     }
  //   }
  // },
  methods: {
    formatDate (date) {
      // UTC corrige le offset de 1 jour
      return new Date(date).toLocaleString(this.$i18n.locale + '-CA', { day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC' })
    },
    closeDialog (value) {
      this.showProfilEdition = value
    }
  }
}
</script>

<template lang='pug'>
.profil-index.split-page
  page-header(:title="$t('menu.profil-ecole')", :backButtonLabel="$t('menu.home')", :backButtonTo="{ name: 'home___' + $i18n.locale, params: { lang: $i18n.locale } }")
  profil-edition(:show='showProfilEdition', @closeDialog='closeDialog', :user='user')

  div.page__main-content
    section.large-section
      .section-subtitle__header
        h2.section-subtitle {{$t('partage.info-gen')}}
      v-sheet.section-card.relative(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
        v-btn(@click='showProfilEdition = !showProfilEdition', style='position: absolute; top: 5px; right: 5px;', icon)
          font-awesome-icon.f5.secondary--text(:icon="['fad', 'pen']")
</template>

<style lang='sass' scoped>
@import 'src/styles/pages/_profil'

</style>

<script>
import { debounce, uniqBy } from 'lodash-es'
export default {
  name: 'ChooseEtablissement',
  props: {
    datetab: { required: false, type: String, default: '' },
    edit: { required: false, type: Boolean, default: true }
  },
  data () {
    return {
      titre: '',
      etablissement: null,
      etablissements: [],
      ecole: null,
      searchEtab: null,
      loading: false
    }
  },
  watch: {
    datetab: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          // this.etablissement = newVal
          this.addCompany(newVal)
        }
      }
    },
    searchEtab: debounce(function searchEtab (newVal, oldVal) {
      if (newVal && newVal !== '' && newVal !== oldVal) {
        this.titre = newVal
        this.searchMore()
      } else {
        this.etablissements = this.ecole
      }
    }, 1000, { trailing: true })
  },
  apollo: {
    ecole: {
      query: require('@/graphql/queries/v2/searchCommunCompagnies.gql'),
      variables () {
        return {
          filtre: { natures: ['ECOLE'], provinces: null, pays: null },
          limit: 100,
          page: 1
        }
      },
      update (data) {
        this.etablissements.push(...data.searchCommunCompagnies.resultat)
        this.etablissements = uniqBy(this.etablissements, 'id')
        return data.searchCommunCompagnies.resultat
      },
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    async addCompany (id) {
      await this.$apollo.query({
        query: require('@/graphql/queries/v2/communCompagnies.gql'),
        variables: {
          ids: [id.titre ? id.id : id]
        }
      }).then(({ data }) => {
        this.etablissement = data.communCompagnies.find(e => e.id === this.datetab)
        this.etablissements.push(...data.communCompagnies)
        this.etablissements = uniqBy(this.etablissements, 'id')
      })
    },
    async searchMore () {
      await this.$apollo.query({
        query: require('@/graphql/queries/v2/searchCommunCompagnies.gql'),
        variables: {
          q: this.titre,
          filtre: { natures: ['ECOLE'], provinces: null, pays: null },
          limit: 100,
          page: 1
        }
      }).then(({ data }) => {
        this.etablissements = data.searchCommunCompagnies.resultat
      })
    },
    customFilter (item, queryText, itemText) {
      let elem = this.titre ? this.titre.toString().toLowerCase() : ''
      let compare = itemText ? itemText.toString().toLowerCase() : ''
      /* eslint-disable */
      const map = {
        '-' : ' ',
        '-' : '_',
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
      }
      /* eslint-enable */
      for (const pattern in map) {
        elem = elem.replace(new RegExp(map[pattern], 'g'), pattern)
        compare = compare.replace(new RegExp(map[pattern], 'g'), pattern)
      }
      return compare.includes(elem)
    },
    async updateValue () {
      if (this.etablissement && this.etablissement.id) {
        this.$emit('select', this.etablissement.id)
      } else if (this.etablissement) {
        // on va créer l'établissement
        this.loading = true
        let result = null
        const research = await this.$apollo.query({
          query: require('@/graphql/queries/v2/searchCommunCompagnies.gql'),
          variables: {
            q: this.etablissement,
            filtre: { natures: ['ECOLE'], provinces: null, pays: null },
            limit: 100,
            page: 1
          }
        }).then(({ data }) => {
          return data.searchCommunCompagnies.resultat
        })
        if (research.length === 0) {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/createCommunCompagnie.gql'),
            variables: {
              attrs: {
                titre: this.etablissement,
                nature: 'ECOLE'
              }
            }
          }).then(({ data }) => {
            if (data.createCommunCompagnie && !data.createCommunCompagnie.error) {
              result = data.createCommunCompagnie
            } else {
              throw new Error('Erreur creation ', data.createCommunCompagnie.error)
            }
          }).catch((e) => {
            throw new Error('Erreur creation ', e)
          })
        } else {
          this.etablissement = research[0]
        }
        if (result) {
          this.etablissements.push(result)
          this.etablissement = result
          this.$emit('select', result.id)
        }
        this.loading = false
      }
    }
  }
}
</script>

<template lang="pug">
.choose-etablissement.w-100(v-if='etablissements')
  //- VOIR EN FONCTION DE CE QUE MAXIME PROPSE COMME MUTATION POUR LA CREATION D'ETABLISSEMENTS SI ON FAIT UNE COMBOBOX OU PLUS
  v-combobox(
    :disabled='loading || !edit',
    v-model='etablissement',
    :items='etablissements',
    item-color='secondary',
    item-text='titre',
    :label='loading ? $t("loading.chargement-ecoles") : $t("form.choix-ecole")',
    color='secondary',
    chips,
    cache-items,
    small-chips,
    @change='updateValue',
    :search-input.sync='searchEtab',
    :filter='customFilter',
    rounded
  )
  //- pre {{etablissement}}
  //- v-autocomplete(
  //-   :disabled='loading'
  //-   v-model='etablissement',
  //-   :label='loading ? $t("loading.chargement-ecoles") : $t("form.choix-ecole")'
  //-   color='secondary',
  //-   :items='etablissements',
  //-   item-value='id',
  //-   item-text='titre',
  //-   item-color='secondary',
  //-   :search-input.sync='searchEtab',
  //-   cache-items,
  //-   :filter='customFilter',
  //-   :no-data-text='$t("alert.aucun-etablissement")',
  //-   @change='updateValue'
  //-   filled, rounded,
  //-   chips,
  //-   small-chips
  //- )
</template>
